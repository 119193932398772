import React, { useContext } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import {
  ApolloProvider, ApolloClient, HttpLink, InMemoryCache, ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import cleanTypenameLink from './utils/cleanTypenameLink';
import AuthContext from './contexts/AuthContext';
import DataContext, { DataProvider } from './contexts/DataContext';
import { MessageProvider } from './contexts/MessageContext';
import MixpanelTracker, { mixpanelClient } from './utils/MixpanelTracker';
import Navbar from './components/Navbar';
import Landing from './pages/Landing';
import FirstTime from './pages/FirstTime';
import UnverifiedEmail from './pages/UnverifiedEmail';
import Tours from './pages/Tours';
import Home from './pages/Home';
import Search from './pages/Search';
import Projects from './pages/Projects';
import Project from './pages/Project';
import ProjectNew from './pages/ProjectNew';
import Schedule from './pages/Schedule';
import Settings from './pages/Settings';
import Meeting from './pages/Meeting';
import List from './pages/List';

const NavbarRoute = ({ path, component: Component }) => (
  <>
    <Navbar />
    <Route path={path}>
      <MessageProvider>
        <Component />
      </MessageProvider>
    </Route>
  </>
);

const LoggedInRoutes = () => {
  const { user, team } = useContext(DataContext);
  const onboardedData = user.settings.filter((s) => s.name === 'clientOnboarded')[0];
  const isOnboarded = onboardedData.value === 'true';
  if (!team) {
    return (
      <Switch>
        <Route path="/" component={FirstTime} />
      </Switch>
    );
  }
  mixpanelClient.identify(user.id);
  // First time signup
  if (!isOnboarded) {
    MixpanelTracker.trackSignup(user, team);
  }
  MixpanelTracker.trackLogin(user, team);
  return (
    <Switch>
      <Route path="/tours/:page" component={Tours} />
      <NavbarRoute path="/search" component={Search} />
      <NavbarRoute path="/projects/new" component={ProjectNew} />
      <NavbarRoute path="/projects/:id" component={Project} />
      <NavbarRoute path="/projects" component={Projects} />
      <NavbarRoute path="/lists/:id" component={List} />
      <NavbarRoute path="/settings/:page" component={Settings} />
      <NavbarRoute path="/schedule" component={Schedule} />
      <NavbarRoute path="/home" component={Home} />
      <NavbarRoute path="/meetings/:id" component={Meeting} />
      <Route
        path="/"
        render={() => <Redirect to={isOnboarded ? '/home' : '/tours/welcome'} />}
      />
    </Switch>
  );
};

const loggedInUserPages = (
  <DataProvider>
    <LoggedInRoutes />
  </DataProvider>
);

const guestUserPages = (
  <Switch>
    <Route path="/">
      <Landing />
    </Route>
  </Switch>
);

const unverifiedUserPages = (
  <Switch>
    <Route path="/" component={UnverifiedEmail} />
  </Switch>
);

const Routes = () => {
  const { token, auth0User } = useContext(AuthContext);
  const httpLink = new HttpLink({ uri: process.env.REACT_APP_GRAPHQL_BASE_URL });
  const authLink = setContext(async (_, { headers }) => ({
    headers: {
      ...headers,
      authorization: token,
    },
  }));

  const client = new ApolloClient({
    link: ApolloLink.from([cleanTypenameLink, authLink, httpLink]),
    cache: new InMemoryCache({
      typePolicies: {
        User: {
          merge: true,
          fields: {
            pendingInvites: { merge: false },
            upcomingMeetings: { merge: false },
            pastMeetings: { merge: false },
            availability: { merge: false },
            managedProjects: { merge: false },
            externalAccounts: { merge: false },
          },
        },
        Team: {
          merge: true,
          fields: {
            members: { merge: false },
          },
        },
        Project: {
          merge: true,
          fields: {
            qualificationQuestions: { merge: false },
          },
        },
        List: {
          fields: { experts: { merge: false } },
        },
        ExternalAccount: {
          fields: { subscribedCalendars: { merge: false } },
        },
      },
    }),
  });

  const getPages = () => {
    if (auth0User?.email_verified) {
      return loggedInUserPages;
    }
    if (auth0User && !auth0User.email_verified) {
      return unverifiedUserPages;
    }
    return guestUserPages;
  };

  return (
    <ApolloProvider client={client}>
      <Router>
        <div>{getPages()}</div>
      </Router>
    </ApolloProvider>
  );
};

export default Routes;
