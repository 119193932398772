import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import {
  Grid, Segment, Icon, Header, List, Pagination,
} from 'semantic-ui-react';
import momentTZ from 'moment-timezone';
import Loading from '../Loading';
import ExportTransactionButton from '../Transactions/ExportTransactionButton';
import TRANSACTIONS from '../../queries/transaction';

const Credits = ({ user, team }) => {
  const renderCreditStats = () => {
    const CREDIT_STATS = [
      {
        topLabel: 'Total Credits',
        value: team.creditBalance,
        bottomLabel: '',
      },
      {
        topLabel: 'On Hold Credits',
        value: team.pendingExpense,
        bottomLabel: 'For Scheduled Interviews',
      },
      {
        topLabel: 'Available Credits',
        value: team.creditBalance - team.pendingExpense,
        bottomLabel: '',
      },
    ];
    return (
      <Grid stackable doubling columns={3}>
        {CREDIT_STATS.map((creditStats) => (
          <Grid.Column key={creditStats.topLabel}>
            <div className="stats-item">
              <div className="label">
                <Icon name="database" />
                {creditStats.topLabel}
              </div>
              <div className="number">{creditStats.value}</div>
              <div className={`footer ${creditStats.bottomLabel === '' && 'empty-spacing'}`}>{creditStats.bottomLabel}</div>
            </div>
          </Grid.Column>
        ))}
      </Grid>
    );
  };

  const renderTransactions = () => {
    const PER_PAGE = 50;
    const [page, setPage] = useState(1);
    const { loading, data } = useQuery(TRANSACTIONS, {
      variables: {
        teamId: team.id,
        pagination: {
          page,
          per_page: PER_PAGE,
        },
      },
    });
    if (loading) return <Loading />;

    const getTransactionInformation = (t) => {
      switch (t.type) {
        case 'Job Payment':
          return `Debit ${Math.abs(t.credits)} credit(s) for ${t.job.expert.fullName} | ${t.job.project.title}`;
        case 'Credit Purchase':
          return `Add ${t.credits} credit(s) to ${t.team.name} team`;
        case 'Bonus Payment':
          return `Bonus payment of $${t.amount} to ${t.job.expert.fullName} | ${t.job.project.title}`;
        default:
          return t.description;
      }
    };

    return (
      <Segment>
        <List>
          {data.transactions.transactions.map((t) => (
            <List.Item key={t.id}>
              {`
                ${momentTZ.tz(t.createdAt, user.timeZone).format('llll z')} | ${getTransactionInformation(t)}
              `}
            </List.Item>
          ))}
        </List>
        <div className="pages">
          <Pagination
            defaultActivePage={page}
            totalPages={Math.ceil(data.transactions.total / PER_PAGE)}
            onPageChange={(e, d) => setPage(d.activePage)}
          />
        </div>
      </Segment>
    );
  };

  return (
    <Segment id="credits">
      <Grid stackable doubling>
        <Grid.Row>
          {`As of ${momentTZ.tz(new Date(), user.timeZone).format('M/D/YY')}`}
          {renderCreditStats()}
        </Grid.Row>
        <Grid.Row className="history-header">
          <Grid.Column verticalAlign="middle">
            <Header>Credit history</Header>
            <ExportTransactionButton />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className="history">
          {renderTransactions()}
        </Grid.Row>
      </Grid>
    </Segment>
  );
};

export default Credits;
